function normalizeFilter(filter = '') {
	if (typeof filter !== 'string') {
		filter = String(filter);
	}

	return filter.normalize('NFKD')
		.replace(/\s/g, '')
		.replace(/[^a-zA-Z0-9 ,]/g, '')
		.toLowerCase();
}

function formatHQ({ city = '', state = '', country }, isShookList) {
	const profileCountry = !isShookList ? country : undefined;
	const hqData = [city, state, profileCountry];
	let hq = '';
	hqData.forEach((item, index) => {
		if (item) {
			if (index < hqData.length && index !== 0 && (index > 0 && (city?.length || state?.length))) {
				hq += ', ';
			}
			hq += item;
		}
	});
	return hq;
}

module.exports = {
	normalizeFilter,
	formatHQ,
};
