const accents = require('remove-accents');
const { default: fireTrackingEvent } = require('../../vendors/tracking');
const tablePagination = require('./tablePagination');
const tableSort = require('./tableSort');
const { processRow, removeOldRows, setRowContainerIndex } = require('./tableRows');
const { normalizeFilter } = require('../../utilities/format');

const {
	table = {},
	columnWidth,
	tableLength,
} = window.forbes['simple-site'];

const filters = {};
const input = document.getElementById('filter');
const dropdown = document.getElementById('dropdown');
const dropdownField = table.dropdownField || '';
const filterField = table.filterField || '';
const isStarRanked = table.isStarRanked;
const isRankHidden = table.isRankHidden;
const orInequalityFilter = table.orInequalityFilter;
const tableElement = document.getElementById('table');
const rows = document.querySelectorAll('.table-row');
const industryRankCells = document.querySelectorAll('.industryRankCell');
const columnGroups = document.querySelectorAll('.column-group');
const headers = document.querySelectorAll('.table-header-text');
const tableColumns = document.querySelectorAll('.column');
const firstHeader = document.querySelector('.first');
const lastHeader = headers[headers.length - 1]?.parentNode;
const firstColumn = document.querySelector('.first.rank-cell');
const firstCells = document.querySelectorAll('.table-row .rank-cell.rank');

let industryRankColumnAttributes = {};

function setIndustryRankColumnWidths() {
	columnGroups.forEach((columnGroup) => {
		const columns = columnGroup.children;
		const dropdownIndustryRankColumn = columns[0];
		const searchIndustryRankColumn = columns[columns.length - 1];
		dropdownIndustryRankColumn.style.width = industryRankColumnAttributes.dropdownIndustryRankColumnWidth;
		dropdownIndustryRankColumn.style.display = industryRankColumnAttributes.dropdownIndustryRankColumnWidth !== '0%' ? 'table-column' : 'none';
		searchIndustryRankColumn.style.width = industryRankColumnAttributes.searchIndustryRankColumnWidth;
		searchIndustryRankColumn.style.display = industryRankColumnAttributes.searchIndustryRankColumnWidth !== '0%' ? 'table-column' : 'none';
	});
}

function showOrHideIndustryRankHeaders() {
	const firstIndustryRankHeader = headers[0];
	const secondIndustryRankHeader = lastHeader;
	firstIndustryRankHeader.classList.toggle('hidden', industryRankColumnAttributes.hideFirstIndustryRankHeader);
	firstHeader.classList.toggle('hidden', !filters[dropdownField]);
	secondIndustryRankHeader.classList.toggle('hidden', industryRankColumnAttributes.hideSecondIndustryRankHeader);
}

function setIndustryRankColumnAttributes(columnWidth1, columnWidth2, hideRankHeader1, hideRankHeader2) {
	industryRankColumnAttributes = {
		dropdownIndustryRankColumnWidth: columnWidth1,
		searchIndustryRankColumnWidth: columnWidth2,
		hideFirstIndustryRankHeader: hideRankHeader1,
		hideSecondIndustryRankHeader: hideRankHeader2,
	};
}

// When search filter is selected, hide the industry rank dropdown column.
// When the dropdown filter is selected, hide the industry rank search column.
function processIndustryRankColumns() {
	tableColumns.forEach((el) => {
		const width = el.getAttribute('width');
		el.style.width = width;
	});
	if (!filters[filterField] && !filters[dropdownField]) {
		setIndustryRankColumnAttributes('0%', '0%', true, true);
	} else if (filters[dropdownField]) {
		setIndustryRankColumnAttributes('15%', '0%', false, true);
	} else {
		setIndustryRankColumnAttributes('0%', '32%', true, false);
		tableColumns.forEach((el) => {
			el.style.width = columnWidth[0];
		});
	}
}

//  Hides or shows the first column of a table based on the selected filter value.

const hideRank = () => {
	if (filters[dropdownField] === undefined) {
		// Hide the first column and cells if no filter is selected.
		firstColumn?.classList?.add('hidden');
		firstCells.forEach((el) => {
			el.classList.add('hidden');
		});
		tableColumns.forEach((el) => {
			el.style.display = 'none';
		});
	} else {
		// Show the first column and cells if a filter is selected.
		firstColumn?.classList?.remove('hidden');
		firstCells.forEach((el) => {
			el.classList.remove('hidden');
		});
		tableColumns.forEach((el) => {
			el.style.display = 'table-column';
			el.style.width = columnWidth[0];
		});
	}
};

// Used to display the applicable content in the industry rank dropdown column
function processByDropdown(industryRank, starRankIndustry, starRank) {
	// this is required for the innerText to be searchable
	starRankIndustry.classList.remove('hidden');
	const startRankState = normalizeFilter(starRankIndustry.innerText.replace(/\s[0-9]\s/g, ''));
	const dropdownFilter = normalizeFilter(filters[dropdownField]).replace(/\s[0-9]\s/g, '');

	if ((startRankState === dropdownFilter && industryRank.parentNode.nextSibling)) {
		starRank.classList.remove('hidden');
	} else if (!industryRank.innerText.includes(dropdownFilter) || industryRank.classList.contains('searchIndustryRank')) {
		starRank.classList.add('hidden');
	}
	// Once search is complete, hide the industry
	starRankIndustry.classList.add('hidden');
}

// Used to display the applicable content in the industry rank search column
function processBySearch(industryRank, starRankIndustry, starRank) {
	industryRank.parentNode.classList.add('hidden');
	if (industryRank.classList.contains('searchIndustryRank')) {
		industryRank.parentNode.classList.remove('hidden');
		lastHeader.parentNode.classList.remove('hidden');
		starRankIndustry.classList.remove('hidden');
		starRank.classList.remove('hidden');
	}
}

function processIndustryRankData(industryRank, starRankIndustry, starRank) {
	if (!filters[filterField] && !filters[dropdownField]) {
		starRankIndustry.classList.add('hidden');
		starRank.classList.add('hidden');
		industryRank.parentNode.classList.add('hidden');
	} else if (filters[dropdownField]) {
		processByDropdown(industryRank, starRankIndustry, starRank);
		industryRank.parentNode.classList.remove('hidden');
	} else {
		processBySearch(industryRank, starRankIndustry, starRank);
	}
}

// Industry Rank cells contain arrays of data and need to be looped to apply filter
// This treatment is only applied to best in country/state/industry list landers
function filterIndustryRanks() {
	if (isStarRanked) {
		industryRankCells.forEach((industryRank, industryRankIndex) => {
			const starRankIndustry = industryRank.querySelector('.starRankIndustry');
			const starRank = industryRank.querySelector('.starRank');

			processIndustryRankData(industryRank, starRankIndustry, starRank);

			if (industryRankIndex === 0) {
				processIndustryRankColumns();
			}
		});
		setIndustryRankColumnWidths();
		showOrHideIndustryRankHeaders();
	}
}

function removeFilters() {
	setRowContainerIndex(0);
	const rowsArray = tableElement.dataset.sortField ? tableSort.sortRows(rows) : rows;
	rowsArray.forEach((tableRow, index) => {
		tableRow.style.display = '';
		tableRow.classList.add('active');
		tableRow.classList.remove('filter');
		processRow(tableRow, index);
	});

	tableElement.dataset.filter = false;
}

function checkFilterType(dropdownFilter, searchFilter) {
	const isSearchSelected = document.activeElement === input;
	const isDropdownSelected = document.activeElement === dropdown;

	if (isSearchSelected) {
		dropdown.selectedIndex = 1;
		dropdownFilter = undefined;
		filters[filterField] = undefined;
	} else if (isDropdownSelected) {
		searchFilter = undefined;
		input.value = '';
		filters[dropdownField] = undefined;
	}
	return { dropdownFilter, searchFilter };
}

function processIsStarRankedFilter({
	dropdownFilter,
	searchFilter,
	node,
	attributeSearch,
	elementAtt,
}) {
	if (dropdownFilter && searchFilter) {
		if (attributeSearch.includes(searchFilter) && elementAtt) {
			return node;
		}
	} else if (dropdownFilter && elementAtt) {
		return node;
	} else if (searchFilter && attributeSearch.includes(searchFilter)) {
		return node;
	}

	return '';
}

function processFilter({
	dropdownFilter, searchFilter, node, attributeSearch, elementAtt,
}) {
	if (!isStarRanked && dropdownFilter && searchFilter) {
		if (((dropdownField === 'industries' && elementAtt.includes(dropdownFilter)) || elementAtt === dropdownFilter)
		&& attributeSearch.includes(searchFilter)) {
			return node;
		}
	} else if (!isStarRanked && dropdownFilter) {
		if ((dropdownField === 'industries' && elementAtt.includes(dropdownFilter))
			|| elementAtt === dropdownFilter) {
			return node;
		}
	} else if (searchFilter && attributeSearch.includes(searchFilter)) {
		return node;
	}
	return '';
}

/** Filter items based on dropFilter and searchFilter attributes for the row
* for dropdownFilter using deep equals and use nodematches for search filter
* @returns {Array} of Filtered items after sorting
*/
function getFilteredRows() {
	let searchFilter = normalizeFilter(filters[filterField]);
	let dropdownFilter = normalizeFilter(filters[dropdownField]);

	if (orInequalityFilter) {
		({ dropdownFilter, searchFilter } = checkFilterType(dropdownFilter, searchFilter));
	}

	const filteredRows = Array.from(rows).filter((node) => {
		let elementAtt = '';
		const attributeSearch = normalizeFilter(node?.getAttribute('searchfilter'));

		if (!isStarRanked) {
			elementAtt = node.getAttribute(dropdownField);
		} else {
			elementAtt = node.getAttribute(dropdownFilter);
		}

		node.rank = elementAtt;

		let row = '';
		const parm = {
			dropdownFilter, searchFilter, node, attributeSearch, elementAtt,
		};
		if (isStarRanked) {
			row = processIsStarRankedFilter({ ...parm });
		} else if (!isStarRanked) {
			row = processFilter({ ...parm });
		}
		return row;
	});

	return tableElement.dataset.sortField ? tableSort.sortRows(filteredRows) : filteredRows;
}

function insertFilters(filteredRows) {
	removeOldRows();
	setRowContainerIndex(0);

	for (let i = 0; i < filteredRows.length; i++) {
		const tableRow = filteredRows[i];
		tableRow.style.borderTop = '';
		tableRow.style.display = '';
		tableRow.classList.add('active', 'filter');
		processRow(tableRow, i);
	}

	tableElement.dataset.filter = true;
}

function runFilters() {
	let length = 0;
	filterIndustryRanks();
	if (!filters[filterField] && !filters[dropdownField]) {
		removeFilters();
		length = tableLength;
	} else {
		const filteredRows = getFilteredRows();
		insertFilters(filteredRows);
		length = filteredRows.length;
	}
	tablePagination.reflow();
	tablePagination.resetPagination(length);
}

// for search filter
input.addEventListener('keyup', () => {
	const filter = accents.remove(input.value.toLowerCase());
	fireTrackingEvent('click', `search:${filter}`);
	filters[filterField] = filter || undefined;
	runFilters();
});

// for dropdown selection
dropdown.addEventListener('change', () => {
	const filter = dropdown.options[dropdown.selectedIndex].value;
	fireTrackingEvent('click', filter);
	filters[dropdownField] = filter === 'All' ? undefined : filter;
	if (isRankHidden) {
		hideRank();
	}
	runFilters(filters);
});

document.addEventListener('DOMContentLoaded', () => {
	if (isStarRanked) {
		tableElement.dataset.sortField = 'starRank:not([hidden])';
		tableElement.dataset.sort = table.isAscendingOrder ? 'asc' : 'desc';
	}
});

module.exports = {
	hideRank,
};
